<template>
  <div class="card p-shadow-6">
    <h1>Helpdesk - Emails Bloqueados</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="list"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="sender"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('addBlockedEmails')"
          >
            <Button icon="pi pi-plus" @click="newSender()" />
          </div>
        </template>
        <Column
          field="sender"
          filterField="sender"
          header="Email"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.active == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="created_user.username"
          filterField="created_user.username"
          header="Adicionado"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{ slotProps.data.created_user.username }}
            |
            {{ slotProps.data.created_at }}

          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="updated_user.username"
          filterField="updated_user.username"
          header="Atualizado"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{ slotProps.data.updated_user.username }}
            |
            {{ slotProps.data.updated_at }}

          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('updateBlockedEmails')"
              icon="pi pi-pencil"
              v-tooltip.left="'Editar'"
              class="p-button-sm p-button-rounded p-button-success"
              @click="editEmail(slotProps.index, slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="senderModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        'Blacklist' +
        (senderModel.id == undefined ? ' Adicionar' : ' Actualizar') +
        ' Email'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form
        name="aclCtegoryForm"
        class="p-col-12"
        @submit.prevent="saveTraining"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-11">
            <span class="p-float-label">
              <InputText
                type="email"
                v-validate="'required|email'"
                v-model="senderModel.sender"
                v-bind:class="[
                  { 'p-invalid': errors.has('sender') },
                  'form-control',
                ]"
                :name="'sender'"
              />
              <label for="sender">Email</label>
            </span>
            <small v-if="errors.has('sender')" class="p-error" role="alert">
              Email é obrigatório|Formato do email incorreto
            </small>
          </div>

          <div class="p-field p-col-12 p-md-1 p-mt-2" v-if="senderModel.id">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="admission">Ativa</label>
            </span>
            <InputSwitch
              v-model="senderModel.active"
              name="admission"
              style="'top: 3px;"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveEmail"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ticketsEmailService from "../services/ticketsEmails.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "HelpdeskBlockedEmails",
  data() {
    return {
      loading: true,
      list: [],
      filters: {
        sender: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "created_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "updated_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      senderModel: { showForm: false },
    };
  },
  async created() {
    this.list = await ticketsEmailService.getSenderToIgnore();
    this.loading = false;
  },
  methods: {
    closeForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.senderModel = { showForm: false });
    },
    newSender() {
      return (this.senderModel.showForm = true);
    },
    editEmail(index, email) {
      return (this.senderModel = {
        index: index,
        showForm: true,
        id: email.id,
        sender: email.sender,
        active: email.active == 1 ? true : false,
        email: email,
      });
    },
    saveEmail() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.senderModel.id === undefined) {
          return this.saveNewEmail();
        }

        return this.updateEmail();
      });
    },
    saveNewEmail() {
      let bodyParams = {
        sender: this.senderModel.sender,
      };

      return ticketsEmailService
        .addSenderToIgnore(bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o email na blacklist",
              life: 3000,
            });
          }
          this.list.push(response);
          this.$toast.add({
            severity: "success",
            summary: "Email inserido",
            detail: "O email foi inserida com sucesso na blacklist",
            life: 3000,
          });
          return this.closeForm();
        });
    },
    updateEmail() {
      let bodyParams = {
        sender: this.senderModel.sender,
      };

      if (this.senderModel.active !== undefined) {
        bodyParams.active = this.senderModel.active;
      }

      const areYou = (itemAux) => {
        return itemAux.id == this.senderModel.id;
      };
      return ticketsEmailService
        .updateSenderToIgnore(this.senderModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar a blacklist",
              life: 3000,
            });
          }
          let index = this.list.findIndex(areYou);
          if (index >= 0) {
            this.list.splice(index, 1);
          }
          this.list.push(response);
          this.senderModel.email = response;
          this.$toast.add({
            severity: "success",
            summary: "Blacklist atualizada",
            detail: "A Blacklist foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeForm();
        });
    },
  },
};
</script>
